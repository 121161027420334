<template>
  <div>
    <div class="backsOut">
      <div class="backs">
        <img src="@/assets/images/fh.png" alt="" @click="goback" />
        <span>关于我们</span>
      </div>
    </div>
    <div class="eargf">
      <div class="title">杭州钱金商务信息服务有限公司</div>
      <div>
        杭州钱金商务信息服务有限公司成立于2018年7月，注册资本
        5000万元，公司注册地址为浙江省杭州市，公司成立1年来一直专
        注于以租代购业务，以解决客户因资金周转卖车后无车可用为己任。
        公司针对不同客户需求，为租车客户设计了不同周期的租车计划，
        是杭州具有影响力的租车服务公司。公司旗下多家分公司，涉及多
        个相关领域，各级分公司员工共计200余人。
      </div>
      <div class="gonsitu">
        <img src="@/assets/images/gsjj.jpg" alt="" />
        <img src="@/assets/images/ggfw.jpg" alt="" />
        <img src="@/assets/images/snmj.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="scss" scoped>
.eargf {
  padding: 1rem;
  .title {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 550;
  }
  .gonsitu {
    width: 100%;
    margin-top: 1rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
